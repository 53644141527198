<template>

    <div class="home">
        <h1>{{ home_title }}</h1>

    </div>

</template>

<script>

    export default {

        name: 'Buchli-Home',
        props: {
            home_title: String

        }
    }

</script>

<style scope>



</style>